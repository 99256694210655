<template>
  <div class="report">
    <div class="content">
      <div class="report__caption">
        Спасибо за проявленный интерес к препарату Сафнело (анифролумаб)!
      </div>
    </div>
    <div class="content">
      <div class="report__description">
       Предлагаем ознакомиться с подборкой полезных материалов о бремени системной красной волчанки.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  metaInfo: {
    title: "Спасибо за проявленный интерес к препарату Сафнело (анифролумаб)!",
  },
  name: "EmailSafnelo",
  mounted() {
    setTimeout(() => {
      history.replaceState('', 'https://az-most.ru/therapeutic-areas/Immunology/skv?access=0fb142004f9ff2f950dc15600c960d7afe0f7ba63567d4e74d1dc49fec097ec8')
      window.open(
        "https://az-most.ru/therapeutic-areas/Immunology/skv?access=0fb142004f9ff2f950dc15600c960d7afe0f7ba63567d4e74d1dc49fec097ec8",
        "_self"
      );
    }, 5000);
  },
};
</script>

<style lang="scss" scoped>
.report {
  padding-top: 32px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 32px;
  }
  &__caption {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__description {
    margin-top: 64px;
    max-width: 800px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;

    & > a {
      color: #d0006f;
    }

    @media screen and (max-width: 1220px) {
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  .breadcrumbs {
    @media screen and (max-width: 767px) {
      padding-top: 16px;
    }
  }
}
</style>

<style lang="scss">
.contacts {
  &__drop {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
    p {
      margin-bottom: 16px;
    }
    a {
      display: inline-block;
      color: #d0006f;
    }
    &.drop-list {
      list-style: disc;
      padding-left: 16px;
    }
    &.drop-item {
      margin-bottom: 16px;
    }
  }
}
</style>